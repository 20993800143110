import { LoginButton1 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton1";
import { RedLoginButton1, BigLoginButton1 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButtons1.tsx";
import { LoginButton2 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton2/LoginButton2.tsx";
import { RedLoginButton2 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton2/LoginButtons2.tsx";
import { MovingLoginButton1 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButtons1.tsx";
import { MovingLoginButton3 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton3/LoginButtons3.tsx";
import { LoginButton3 } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton3/LoginButton3.tsx";
import { MovingLoginButton3Fixed } from "../../../../content/entry/2020-12-19/css-component-design/components/LoginButton3/LoginButtons3.tsx";
import * as React from 'react';
export default {
  LoginButton1,
  RedLoginButton1,
  BigLoginButton1,
  LoginButton2,
  RedLoginButton2,
  MovingLoginButton1,
  MovingLoginButton3,
  LoginButton3,
  MovingLoginButton3Fixed,
  React
};